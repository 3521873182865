import React from 'react';
import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div style={{ display: "flex", flexDirection: "row", textAlign: "left" }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Link to="/">
                <img src="/dice.png" alt="Company Logo" style={{ width: "400px" }} />
            </Link>
            <h1>About Us</h1>
            {/* <p style={{ fontStyle: "italic", marginBottom: "10px" }}>Innovation, Reimagined.</p> */}
            <hr style={{ border: "1px solid #ffffff", margin: "20px auto", width: "50%" }} />
            <div style={{ margin: "0 20%" }}>
                <p>At Delightful Ice, we're on a mission to familiarize ourselves more with the frozen drip of joy by crafting the most flavorful and refreshing smooth ice experience. We believe that ice is more than just a frozen treat, it's a canvas waiting to be explored. Our pursuit is to unlock the full potential of ice creating a truly unique and unparalleled experience.</p>

                <p>Our vision is to create a world where the enjoyment of ice is elevated to an art form. We're dedicated to bring delighful energy to any event one delicious scoop at a time.</p>

                <p>As we continue to grow, we're committed to staying true to our values and looking for the best additions to our offerings. From our unique flavors to our commitment to community, Delightful Ice is of course delighted to be a part of the experience of the joys of ice like never before.</p>
            </div>
        </div>
    </div>
  );
};

export default About;