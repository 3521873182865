import React from 'react';
// import { Link } from 'react-router-dom';


const styles = {
    cloudWebsite: {
      minHeight: '100vh',
      // backgroundColor: '#e6f2ff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    heroSection: {
      position: 'relative',
      width: '100%',
      // height: '384px', 
      // background: 'linear-gradient(to bottom, #a8d0ff, #e6f2ff)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '23px',
    },
    window: {
      marginTop: '120px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cloudIcon: {
      color: '#3b82f6',
    },
    companyLogo: {
      // position: 'absolute',
      // top: '26%',
      // left: '50%',
      // transform: 'translate(-50%, -50%)',
      padding: '6px',
    },
    companyLogoImg: {
      width: '600px',
    },
    companyWindowImg: {
      width: '80%',
    },
    mottoSection: {
      textAlign: 'center',
      marginTop: '32px',
      padding: '0 16px',
    },
    mottoHeading: {
      fontSize: '1.80rem',
      fontWeight: 'bold',
      color: 'rgb(39, 6, 96)',
      paddingTop: '20px',
    },
    mottoParagraph: {
      marginTop: '8px',
      fontSize: '1.125rem',
      color: '#342419',
      margin: '0 20%',
    },
    cardsSection: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '48px',
      padding: '0 16px',
    //   flexDirection: 'row',
    //   "@media (maxWidth: 700px)": {
    //     flexDirection: 'column',
    //   }
    },
    card: {
      // backgroundImage: 'linear-gradient(to bottom, white, #c9e5f0)',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      padding: '24px',
      width: '240px',
      margin: '20px 0px',
    },
    cardHeading: {
      fontSize: '1.25rem',
      fontWeight: '600',
      color: 'rgb(39, 6, 96)',
    },
    cardParagraph: {
      marginTop: '8px',
      color: 'black',
    },
};


function Home() {
  return (
    <div style={styles.cloudWebsite}>
        <div style={styles.heroSection}>
            <div style={styles.companyLogo}>
                <img src="/dice.png" alt="Company Logo" style={styles.companyLogoImg} className='dice-logo' />
            </div>
        </div>

        <div style={styles.mottoSection}>
            <h1 style={styles.mottoHeading}>Icy Delightfulness</h1>
            <p style={styles.mottoParagraph}>
              Indulge in the most sublime icy experiences, born from our fervent passion for the extraordinary. 
              We're obsessed with discovering the most coveted brands and flavors, driven by an insatiable quest for the unknown. 
              Our team embarks on a relentless pursuit to curate the most singular and delectable products for you to savor. 
              From timeless classics to trailblazing innovations, we're perpetually on the lookout for the next transcendent sensation. 
            </p>
        </div>

        <div style={styles.cardsSection} className='cardsec'>
            <div style={styles.card}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="/favicon.png" alt="Fav Icon" style={{ marginLeft: '-13px', width: '50px' }} />
                    <h2 style={styles.cardHeading}>Events</h2>
                </div>
                <p style={styles.cardParagraph}>New (<i style={{color: 'rgb(120, 85, 164)'}}>Coming Soon</i>)</p>
            </div>

            <div style={styles.card} className='card2'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="/favicon.png" alt="Fav Icon" style={{ marginLeft: '-13px', width: '50px' }} />
                    <h2 style={styles.cardHeading}>Store</h2>
                </div>
                <p style={styles.cardParagraph}>New (<i style={{color: 'rgb(120, 85, 164)'}}>Coming Soon</i>)</p>
            </div>
        </div>
    </div>
  );
}

export default Home;